import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: '首页 | BrickSDK' },
  },
  {
    path: '/about',
    name: 'About',
    meta: { title: '关于我们 | BrickSDK' },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/contactus',
    name: 'Contactus',
    meta: { title: '联系我们 | BrickSDK' },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Contactus.vue'),
  },
  {
    path: '/faqs',
    name: 'Faqs',
    component: () => import(/* webpackChunkName: "faqs" */ '../views/Faqs.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    meta: { title: '注册 | BrickSDK' },
    component: () =>
      import(/* webpackChunkName: "register" */ '../views/Register.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: '登录 | BrickSDK' },
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/forget',
    name: 'Forget',
    meta: { title: '忘记密码 | BrickSDK' },
    component: () =>
      import(/* webpackChunkName: "forget" */ '../views/Forget.vue'),
  },
  {
    path: '/docs',
    name: 'Docs',
    meta: { title: '文档中心 | BrickSDK' },
    component: () => import(/* webpackChunkName: "docs" */ '../views/Docs.vue'),
  },
  {
    path: '/Docinfo',
    name: 'DocInfo',
    component: () =>
      import(/* webpackChunkName: "docinfo" */ '../views/DocInfo.vue'),
  },
  {
    path: '/console',
    component: () =>
      import(/* webpackChunkName: "Console" */ '../views/Backend/index.vue'),
    redirect: '/console/application',
    children: [
      {
        path: 'application',
        name: 'Application',
        component: () => import('@/views/Backend/Application'),
        meta: {
          title: '应用中心 | BrickSDK',
          needToken: true,
        },
      },
      {
        path: 'third',
        name: 'Third',
        component: () => import('@/views/Backend/Third'),
        meta: { title: '配置管理 | BrickSDK', needToken: true },
      },
      {
        path: 'userdata',
        name: 'Userdata',
        component: () => import('@/views/Backend/Userdata'),
        meta: { title: '用户数据 | BrickSDK', needToken: true },
      },
      {
        path: 'paydata',
        name: 'Paydata',
        component: () => import('@/views/Backend/Paydata'),
        meta: { title: '充值数据 | BrickSDK', needToken: true },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
